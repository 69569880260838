import {announce} from '@github-ui/aria-live'
import {observe} from '@github/selector-observer'

const sendRequest = async (url: string, csrfToken: string, formMethod: string, vote: boolean) => {
  const form = new FormData()
  if (vote === true) {
    form.append('upvote', 'true')
  }

  return await fetch(url, {
    body: formMethod === 'delete' ? '' : form,
    method: formMethod,
    mode: 'same-origin',
    headers: {
      'Scoped-CSRF-Token': csrfToken,
    },
  })
}

class VoteCountElement {
  voteCountElement: HTMLElement | null

  constructor(voteCountElement: HTMLElement | null) {
    this.voteCountElement = voteCountElement
  }

  getLabel(): string {
    return this.voteCountElement?.getAttribute('data-upvote-label') || ''
  }

  getText(): string {
    return this.voteCountElement?.textContent || ''
  }
}

class VoteFormElement {
  voteForm: HTMLElement
  voteButton: HTMLButtonElement | null
  defaultVoteCountElement: VoteCountElement
  upvotedCountElement: VoteCountElement
  url: string

  constructor(voteForm: HTMLElement) {
    this.voteForm = voteForm
    this.voteButton = this.voteForm.querySelector<HTMLButtonElement>('.js-upvote-button')
    this.defaultVoteCountElement = new VoteCountElement(voteForm.querySelector<HTMLElement>('.js-default-vote-count'))
    this.upvotedCountElement = new VoteCountElement(voteForm.querySelector<HTMLElement>('.js-upvoted-vote-count'))
    this.url = this.voteForm.getAttribute('data-url') || ''
  }

  isUpvoted(): boolean {
    const upvoted = this.voteForm.getAttribute('data-upvoted')
    return upvoted === 'true'
  }

  getCsrfDeleteInputValue(): string {
    const csrfDeleteInput = this.voteForm.querySelector<HTMLInputElement>('.js-data-url-delete-csrf')
    return csrfDeleteInput ? csrfDeleteInput.value : ''
  }

  getCsrfPutInputValue(): string {
    const csrfPutInput = this.voteForm.querySelector<HTMLInputElement>('.js-data-url-put-csrf')
    return csrfPutInput ? csrfPutInput.value : ''
  }

  simulateUpvote(): void {
    this.voteForm.setAttribute('data-upvoted', 'true')

    if (this.voteForm.getAttribute('data-new-upvote')) {
      this.voteForm.querySelector('.js-upvote-button')?.classList.add('user-has-reacted', 'color-bg-accent')
      this.voteForm.querySelector('.js-upvote-button')?.classList.remove('color-fg-muted')
    }

    this.voteForm.classList.add('is-upvoted')
    this.voteButton?.setAttribute('aria-label', this.upvotedCountElement.getLabel())
    this.voteButton?.setAttribute('aria-pressed', 'true')
    announce(`${this.upvotedCountElement.getText()} Upvotes`)
  }

  simulateUpvoteDeletion(): void {
    this.voteForm.setAttribute('data-upvoted', 'false')

    if (this.voteForm.getAttribute('data-new-upvote')) {
      this.voteForm.querySelector('.js-upvote-button')?.classList.remove('user-has-reacted', 'color-bg-accent')
      this.voteForm.querySelector('.js-upvote-button')?.classList.add('color-fg-muted')
    }

    this.voteForm.classList.remove('is-upvoted')
    this.voteButton?.setAttribute('aria-label', this.defaultVoteCountElement.getLabel())
    this.voteButton?.setAttribute('aria-pressed', 'false')
    announce(`${this.defaultVoteCountElement.getText()} Upvotes`)
  }

  displayUpVoteError(errorMessage: string): void {
    const errorDiv = this.voteForm.querySelector<HTMLElement>('.js-upvote-error')
    if (!(errorDiv instanceof HTMLElement)) return
    errorDiv.textContent = errorMessage
    errorDiv.hidden = false
  }

  hideVoteErrors(): void {
    const upVoteErrorDiv = this.voteForm.querySelector<HTMLElement>('.js-upvote-error')
    if (!(upVoteErrorDiv instanceof HTMLElement)) return
    upVoteErrorDiv.hidden = true
  }
}

class Upvote {
  voteFormElement: VoteFormElement

  constructor(voteFormElement: VoteFormElement) {
    this.voteFormElement = voteFormElement
  }

  animateUpvote(isUpvoted: boolean): void {
    if (isUpvoted) {
      this.voteFormElement.simulateUpvoteDeletion()
    } else {
      this.voteFormElement.simulateUpvote()
    }
  }

  animateUpvoteUndo(isUpvoted: boolean): void {
    if (isUpvoted) {
      this.voteFormElement.simulateUpvote()
    } else {
      this.voteFormElement.simulateUpvoteDeletion()
    }
  }

  async click(): Promise<void> {
    this.voteFormElement.hideVoteErrors()
    const isUpvoted = this.voteFormElement.isUpvoted()
    const csrfInput = isUpvoted
      ? this.voteFormElement.getCsrfDeleteInputValue()
      : this.voteFormElement.getCsrfPutInputValue()
    const formMethod = isUpvoted ? 'delete' : 'put'
    this.animateUpvote(isUpvoted)
    const response = await sendRequest(this.voteFormElement.url, csrfInput, formMethod, !isUpvoted)
    if (!response.ok) {
      if (response.status === 422) {
        const data = await response.json()
        this.voteFormElement.displayUpVoteError(data.error)
        this.animateUpvoteUndo(isUpvoted)
      }
    }
  }
}

observe('.js-upvote-button', voteButton => {
  if (!(voteButton instanceof HTMLElement)) return
  if (!(voteButton.closest('.discussion-vote-form') instanceof HTMLElement)) return
  const upvote = new Upvote(new VoteFormElement(voteButton.closest('.discussion-vote-form') as HTMLElement))
  voteButton.addEventListener('click', async () => {
    await upvote.click()
  })
})

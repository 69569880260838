import {fire, on} from 'delegated-events'

on('click', '.js-poll-option-template-button', function (event) {
  const button = event.currentTarget as HTMLElement

  const parent = button.parentElement!
  const destroyField = parent.querySelector<HTMLInputElement>('.js-poll-option-destroy-field')
  const pollOptionContainer = parent.closest('.js-discussion-poll-form-component')

  if (destroyField) {
    destroyField.value = 'true'
    destroyField.nextElementSibling?.classList.remove('js-poll-option')
    parent.hidden = true
  } else {
    parent.remove()
  }

  const getOptionBtn = document.querySelector<HTMLElement>('.js-add-poll-option')

  if (getOptionBtn?.hidden) {
    getOptionBtn.hidden = false
    getOptionBtn.setAttribute('aria-hidden', 'false')
  }

  if (pollOptionContainer) {
    fire(pollOptionContainer, 'pollOptionRemoved')
  }
})

on('click', '.js-add-poll-option', function (event) {
  const getOptionBtn = event.currentTarget as HTMLElement
  const pollOptionContainer = document.querySelector<HTMLElement>('#poll-option-container')!
  const pollOptionsLimit: number = +pollOptionContainer.getAttribute('data-poll-options-limit')!
  const pollOptionTemplate = document.querySelector<HTMLElement>('.js-poll-option-template')!
  const isNewDiscussion: boolean = pollOptionContainer.getAttribute('data-new-discussion') === 'true'

  if (pollOptionContainer && pollOptionTemplate) {
    const newPollOption = pollOptionTemplate.cloneNode(true) as HTMLElement
    newPollOption.hidden = false

    const input = newPollOption.querySelector<HTMLInputElement>('.js-poll-option-template-input')!

    if (isNewDiscussion) {
      input.name = `discussion[poll_attributes][options_attributes][][option]`
    } else {
      const indexCounter = pollOptionContainer.getAttribute('data-poll-option-next-key')!
      input.name = `discussion[poll_attributes][options_attributes][${indexCounter}][option]`

      // We need to add to this to ensure that we always have a unique key for our forms
      pollOptionContainer.setAttribute('data-poll-option-next-key', `${parseInt(indexCounter) + 1}`)
    }

    pollOptionContainer.appendChild(newPollOption)
  }

  // We remove `.js-poll-option` when marking an existing option for deletion,
  // so this count is only non-deleted options
  const optionsCount = pollOptionContainer.querySelectorAll('.js-poll-option').length

  if (getOptionBtn && optionsCount >= pollOptionsLimit) {
    getOptionBtn.hidden = true
    getOptionBtn.setAttribute('aria-hidden', 'true')
  }
})

on('change', '#js-discussion-title', function (event) {
  const title = event.currentTarget as HTMLInputElement
  const searchLink = document.querySelector<HTMLAnchorElement>('#js-similar-search-link')

  if (searchLink instanceof HTMLAnchorElement && title instanceof HTMLInputElement) {
    const searchUrl = new URL(searchLink.getAttribute('data-path')!, window.location.origin)
    searchUrl.searchParams.set('discussions_q', title.value)
    searchLink.href = searchUrl.toString()
  }
})

import {controller, target} from '@github/catalyst'
import {TemplateInstance} from '@github/template-parts'

@controller
class CommentActionsContainerElement extends HTMLElement {
  @target declare dialogTemplate: HTMLTemplateElement
  @target declare dialogContainer: HTMLDivElement

  displayDialog(event: Event) {
    const button = event.currentTarget as HTMLButtonElement
    const dialogId = button.getAttribute('data-dialog-id')!

    const dialogExist = this.dialogContainer.querySelector<HTMLDialogElement>(`#${dialogId}`)
    if (!dialogExist) {
      this.createDialog(dialogId, button)
    }
    this.showDialog(dialogId)
  }

  private createDialog(dialogId: string, button: HTMLButtonElement) {
    const title = button.getAttribute('data-title')!
    const src = button.getAttribute('data-src')!
    const size = button.getAttribute('data-size')!
    const newDialog = new TemplateInstance(this.dialogTemplate, {title, dialogId, src})

    this.dialogContainer.append(newDialog)
    if (size) {
      const dialog = this.querySelector<HTMLDialogElement>(`#${dialogId}`)!
      dialog.classList.remove('Overlay--size-medium')
      dialog.classList.add(`Overlay--size-${size}`)
    }
  }

  private showDialog(dialogId: string) {
    const dialog = this.querySelector<HTMLDialogElement>(`#${dialogId}`)
    if (dialog) {
      dialog.showModal()
    }
  }
}

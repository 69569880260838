import {blurForm, focusForm} from '../behaviors/inline-comment'
import type {Quote} from '@github/quote-selection'
import {TemplateInstance} from '@github/template-parts'
import {observe} from '@github/selector-observer'
import {on} from 'delegated-events'
import {remoteForm} from '@github/remote-form'
import {restoreFieldsFromAllBackends} from '../behaviors/session-resume'

type InsertFormOptions = {
  willFocus: boolean
}

observe('.js-inline-comment-form-container', {
  add: element => conditionallyInsertForm(element as HTMLElement),
})

on('click', '.js-discussions-previewable-comment-form', function ({currentTarget}) {
  insertForm(currentTarget, {willFocus: true})
  // this must be called after the form exists in the DOM
  restoreFieldsFromAllBackends()
})

on('quote-selection', '.js-discussion-quote-selection', function (event) {
  const quote = event.detail as Quote
  const target = quote.closest('.js-timeline-discussion-comment')
  if (!target) return
  const range = quote.range
  // Focus form will reset the Range
  insertForm(target, {willFocus: true})
  quote.range = range
})

function conditionallyInsertForm(element: HTMLElement) {
  const commentContainer = element.closest<HTMLElement>('.discussion-timeline-item.js-comment-container')

  if (commentContainer) {
    const willOpen = commentContainer.getAttribute('data-open-edit-form-after-load')

    if (willOpen === 'true') {
      commentContainer.setAttribute('data-open-edit-form-after-load', 'false')
      insertForm(element, {willFocus: true})
    }
  }
}

function insertForm(currentTarget: Element, options: InsertFormOptions) {
  const commentContainer = currentTarget.closest<HTMLElement>('.js-timeline-discussion-comment')!
  const targetContainer = commentContainer.querySelector<HTMLElement>(
    '.js-discussions-previewable-comment-form-container',
  )
  if (!targetContainer) return
  const commentId = targetContainer.getAttribute('data-parent-comment-id')!
  const anchorId = targetContainer.getAttribute('data-anchor-id')!
  const backPage = targetContainer.getAttribute('data-back-page')!
  const template = document.querySelector<HTMLTemplateElement>('template.js-new-comment-template')
  if (commentId && template && !targetContainer.querySelector('.js-discussion-comment-form')) {
    targetContainer.textContent = ''
    targetContainer.appendChild(
      new TemplateInstance(template, {
        id: commentId,
        anchorId,
        backPage,
      }),
    )
  }

  if (options.willFocus) {
    focusForm(commentContainer)
  }
}

remoteForm(
  '.js-discussions-previewable-comment-form-container .js-discussion-comment-form',
  async function (form, wants) {
    await wants.text()
    blurForm(form)
    const container = form.closest('.js-discussions-previewable-comment-form-container')!
    // eslint-disable-next-line github/no-d-none
    container.querySelector('.js-comment-form-error')?.classList.add('d-none')
  },
)
